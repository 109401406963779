import React from "react"
import { Fragment } from "react"
import { HiCheck, HiMinus } from "react-icons/hi"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const tiers = [
  {
    name: "Basic",
    href: "/contact",
    priceMonthly: "",
    description:
      "Individuals looking to translate long videos into editable text documents.",
  },
  {
    name: "Essential",
    href: "/contact",
    priceMonthly: "",
    description:
      "Small to medium sized businesses needing a searchable database of video transcripts.",
  },
  {
    name: "Enterprise",
    href: "/contact",
    priceMonthly: "",
    description: "Large-scale organizational knowledge retention platform.",
  },
]
const sections = [
  {
    name: "Features",
    features: [
      {
        name: "Generate transcript document from uploaded videos.",
        tiers: { Basic: true, Essential: true, Enterprise: true },
      },
      {
        name: "Searchable database of uploaded video transcripts.",
        tiers: { Essential: true, Enterprise: true },
      },
      {
        name: "Organization theme and branding options",
        tiers: { Essential: true, Enterprise: true },
      },
      {
        name: "Advanced analytics and reporting capabilities",
        tiers: { Enterprise: true },
      },
    ],
  },
  {
    name: "Security",
    features: [
      {
        name: "Password protection",
        tiers: { Basic: true, Essential: true, Enterprise: true },
      },
      {
        name: "End-to-end SSL encryption",
        tiers: { Basic: true, Essential: true, Enterprise: true },
      },
      {
        name: "SSO availability",
        tiers: { Enterprise: true },
      },
    ],
  },
  {
    name: " Storage and usage",
    features: [
      {
        name: "Dedicated account manager",
        tiers: { Enterprise: true },
      },
      {
        name: "Storage included",
        tiers: {
          Basic: "2 GB",
          Essential: "200 GB",
          Enterprise: "Unlimited",
        },
      },
      {
        name: "Hours of transcript generation included",
        tiers: {
          Basic: "10 hours",
          Essential: "20 hours",
          Enterprise: "Unlimited",
        },
      },
      {
        name: "User limit",
        tiers: {
          Basic: "Single user",
          Essential: "Up to 20 users",
          Enterprise: "Up to 20,000 users",
        },
      },
    ],
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const PricingPage = () => (
  <Layout>
    <Seo title="Pricing" />
    <div className="bg-white">
      <section className="relative bg-gray-50 overflow-hidden">
        <div
          className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full"
          aria-hidden="true"
        >
          <div className="relative h-full max-w-7xl mx-auto">
            <svg
              className="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={784}
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={784}
                fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)"
              />
            </svg>
          </div>
        </div>

        <div className="relative my-16 mx-auto max-w-7xl px-4 sm:my-24">
          <div className="text-center">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span className="xl:inline">Our</span>{" "}
              <span className="text-indigo-600 xl:inline">Pricing</span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Pricing built for organizations of all sizes. Compare pricing
              plans and top features below so you'll always know what you'll
              pay.
            </p>
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md shadow">
                <Link
                  to="/solutions"
                  className="w-full flex items-center justify-center px-8 py-2 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-3 md:text-lg md:px-10"
                >
                  Learn More
                </Link>
              </div>
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                <Link
                  to="/contact"
                  className="w-full flex items-center justify-center px-8 py-2 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50 md:py-3 md:text-lg md:px-10"
                >
                  Contact Sales
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="max-w-7xl mx-auto bg-white py-16 sm:py-24 sm:px-6 lg:px-8">
        {/* xs to lg */}
        <div className="max-w-2xl mx-auto space-y-16 lg:hidden">
          {tiers.map((tier, tierIdx) => (
            <section key={tier.name}>
              <div className="px-4 mb-8">
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                  {tier.name}
                </h2>
                <p className="mt-4">
                  <span className="text-4xl font-extrabold text-gray-900">
                    {tier.priceMonthly}
                  </span>{" "}
                  <span className="text-base font-medium text-gray-500">
                    {tier.priceMonthly ? (
                      <span className="text-base font-medium text-gray-500">
                        /mo
                      </span>
                    ) : (
                      <span className="text-2xl font-medium text-gray-700">
                        Contact Us
                      </span>
                    )}
                  </span>
                </p>
                <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
                <Link
                  to={tier.href}
                  className="mt-6 block border border-gray-800 rounded-md bg-gray-800 w-full py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                >
                  Request {tier.name}
                </Link>
              </div>

              {sections.map(section => (
                <table key={section.name} className="w-full">
                  <caption className="bg-gray-50 border-t border-gray-200 py-3 px-4 text-sm font-medium text-gray-900 text-left">
                    {section.name}
                  </caption>
                  <thead>
                    <tr>
                      <th className="sr-only" scope="col">
                        Feature
                      </th>
                      <th className="sr-only" scope="col">
                        Included
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {section.features.map(feature => (
                      <tr
                        key={feature.name}
                        className="border-t border-gray-200"
                      >
                        <th
                          className="py-5 px-4 text-sm font-normal text-gray-500 text-left"
                          scope="row"
                        >
                          {feature.name}
                        </th>
                        <td className="py-5 pr-4">
                          {typeof feature.tiers[tier.name] === "string" ? (
                            <span className="block text-sm text-gray-700 text-right">
                              {feature.tiers[tier.name]}
                            </span>
                          ) : (
                            <>
                              {feature.tiers[tier.name] === true ? (
                                <HiCheck
                                  className="ml-auto h-5 w-5 text-green-500"
                                  aria-hidden="true"
                                />
                              ) : (
                                <HiMinus
                                  className="ml-auto h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              )}

                              <span className="sr-only">
                                {feature.tiers[tier.name] === true
                                  ? "Yes"
                                  : "No"}
                              </span>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))}

              <div
                className={classNames(
                  tierIdx < tiers.length - 1 ? "py-5 border-b" : "pt-5",
                  "border-t border-gray-200 px-4"
                )}
              >
                <Link
                  to={tier.href}
                  className="block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                >
                  Request {tier.name}
                </Link>
              </div>
            </section>
          ))}
        </div>

        {/* lg+ */}
        <div className="hidden lg:block">
          <table className="w-full h-px table-fixed">
            <caption className="sr-only">Pricing plan comparison</caption>
            <thead>
              <tr>
                <th
                  className="pb-4 px-6 text-sm font-medium text-gray-900 text-left"
                  scope="col"
                >
                  <span className="sr-only">Feature by</span>
                  <span>Plans</span>
                </th>
                {tiers.map(tier => (
                  <th
                    key={tier.name}
                    className="w-1/4 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left"
                    scope="col"
                  >
                    {tier.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="border-t border-gray-200 divide-y divide-gray-200">
              <tr>
                <th
                  className="py-8 px-6 text-sm font-medium text-gray-900 text-left align-top"
                  scope="row"
                >
                  Pricing
                </th>
                {tiers.map(tier => (
                  <td key={tier.name} className="h-full py-8 px-6 align-top">
                    <div className="relative h-full table">
                      <p>
                        <span className="text-4xl font-extrabold text-gray-900">
                          {tier.priceMonthly}
                        </span>{" "}
                        {tier.priceMonthly ? (
                          <span className="text-base font-medium text-gray-500">
                            /mo
                          </span>
                        ) : (
                          <span className="text-2xl font-medium text-gray-700">
                            Contact Us
                          </span>
                        )}
                      </p>
                      <p className="mt-4 mb-16 text-sm text-gray-500">
                        {tier.description}
                      </p>
                      <Link
                        to={tier.href}
                        className="absolute bottom-0 flex-grow block w-full bg-gray-800 border border-gray-800 rounded-md 5 py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                      >
                        Request {tier.name}
                      </Link>
                    </div>
                  </td>
                ))}
              </tr>
              {sections.map(section => (
                <Fragment key={section.name}>
                  <tr>
                    <th
                      className="bg-gray-50 py-3 pl-6 text-sm font-medium text-gray-900 text-left"
                      colSpan={4}
                      scope="colgroup"
                    >
                      {section.name}
                    </th>
                  </tr>
                  {section.features.map(feature => (
                    <tr key={feature.name}>
                      <th
                        className="py-5 px-6 text-sm font-normal text-gray-500 text-left"
                        scope="row"
                      >
                        {feature.name}
                      </th>
                      {tiers.map(tier => (
                        <td key={tier.name} className="py-5 px-6">
                          {typeof feature.tiers[tier.name] === "string" ? (
                            <span className="block text-sm text-gray-700">
                              {feature.tiers[tier.name]}
                            </span>
                          ) : (
                            <>
                              {feature.tiers[tier.name] === true ? (
                                <HiCheck
                                  className="h-5 w-5 text-green-500"
                                  aria-hidden="true"
                                />
                              ) : (
                                <HiMinus
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              )}

                              <span className="sr-only">
                                {feature.tiers[tier.name] === true
                                  ? "Included"
                                  : "Not included"}{" "}
                                in {tier.name}
                              </span>
                            </>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
            <tfoot>
              <tr className="border-t border-gray-200">
                <th className="sr-only" scope="row">
                  Choose your plan
                </th>
                {tiers.map(tier => (
                  <td key={tier.name} className="pt-5 px-6">
                    <Link
                      to={tier.href}
                      className="block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                    >
                      Request {tier.name}
                    </Link>
                  </td>
                ))}
              </tr>
            </tfoot>
          </table>
        </div>
      </section>
    </div>
  </Layout>
)

export default PricingPage
